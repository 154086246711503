import type { Octave, SPN } from "./types";

export enum Provider {
  GoogleDrive = "Google Drive",
}

export const supportedCompressionMimeTypes = [
  "application/zip",
  "application/x-zip-compressed",
  // "application/rar",
  // "application/x-rar",
];

// file signatures (aka "magic numbers")
// https://en.wikipedia.org/wiki/List_of_file_signatures
// also see Gary Kessler's File Signatures Table --> https://www.garykessler.net/library/file_sigs.html
export enum FileSig {
  AAC = "fff15080",
  FLAC = "664c6143",
  M4A = "00018",
  MP3a = "0001c",
  MP3b = "4944333",
  MP4 = "00020",
  Ogg = "4f676753",
  WAV = "52494646",
  WebM = "1a45dfa3",
}

export enum PitchClass {
  C = "C",
  Csharp = "C#",
  D = "D",
  Dsharp = "D#",
  E = "E",
  F = "F",
  Fsharp = "F#",
  G = "G",
  Gsharp = "G#",
  A = "A",
  Asharp = "A#",
  B = "B",
}

export const octaves = [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;

const appendOctave = (octaveNumber: Octave) => (note: PitchClass) =>
  (note + octaveNumber) as SPN;

const assembleOctave = (octaveNumber: Octave) =>
  Object.values(PitchClass).map(appendOctave(octaveNumber));

export const pitches = octaves.flatMap(assembleOctave);
