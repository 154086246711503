// import styles from "~/components/Launchpads/styles.module.css";
import type { AudioPlayer } from "./audio-player";
import type { SPN } from "~/types";
import type { KeysMatching } from "~/utils/types";

type SelectEvent = KeysMatching<
  GlobalEventHandlersEventMap,
  MouseEvent | TouchEvent
>;

const SELECT_EVENTS: SelectEvent[] = [
  "mousedown",
  "mouseup",
  "touchend",
  "touchstart",
];

type PadSelectEvent = HTMLDivMouseEvent | HTMLDivTouchEvent;

interface HTMLDivMouseEvent extends Omit<MouseEvent, "target"> {
  target: HTMLButtonElement;
}

interface HTMLDivTouchEvent extends Omit<TouchEvent, "target"> {
  target: HTMLButtonElement;
}

export const createListeners = (trigger: AudioPlayer["trigger"]) => {
  // Mouse & touch events
  const onUIEvent = (event: PadSelectEvent) => {
    if (
      (event instanceof MouseEvent &&
        event.button === 0 &&
        event.type === "mousedown") ||
      event.type === "touchstart"
    ) {
      trigger(event.target.id as SPN);
    }
  };

  const selectListener = (event: MouseEvent | TouchEvent) => {
    if (event.target instanceof HTMLButtonElement && event.target.dataset.pad) {
      // Prevent both touch and mouse events to be fired on a single user input.
      // https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Supporting_both_TouchEvent_and_MouseEvent#Event_firing
      event.preventDefault();
      onUIEvent(event as PadSelectEvent);
    }
  };

  const addSelectListener = (parentDiv: HTMLDivElement) => {
    for (const event of SELECT_EVENTS) {
      parentDiv.addEventListener(event, selectListener);
    }
  };

  const removeSelectListener = (parentDiv: HTMLDivElement) => {
    for (const event of SELECT_EVENTS) {
      parentDiv.removeEventListener(event, selectListener);
    }
  };

  const addListeners = (parentDiv: HTMLDivElement, inputId?: string) => {
    addSelectListener(parentDiv);
  };

  const removeListeners = (parentDiv: HTMLDivElement, inputId?: string) => {
    removeSelectListener(parentDiv);
  };

  return {
    addListeners,
    removeListeners,
  };
};
// http://localhost:3000/googledrive/1INl-VwlbptfWqs7bwrA1S9_uLBFXpLEO
