import isMobile from "is-mobile";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

const BASE_FONT_SIZE = 16;

const convertToRem = (pixels: number) => `${pixels / BASE_FONT_SIZE}rem`;

interface Props {
  children: ReactNode;
}

export const MobileScrollBar = ({ children }: Props) => {
  const [scrollbarIsNeeded, setScrollbarIsNeeded] = useState(false);
  const [scrollbarHeight, setScrollbarHeight] = useState(0);
  const [scrollbarPosition, setScrollbarPosition] = useState(0);

  const containerRef = useRef<HTMLDivElement>(null);

  const onResize = useCallback(() => {
    const { clientHeight: visibleHeight } = document.documentElement;
    const { scrollHeight } = document.documentElement;

    const docIsHigherThanWindow = scrollHeight > visibleHeight;
    setScrollbarIsNeeded(isMobile() && docIsHigherThanWindow);

    const ratio = visibleHeight / scrollHeight;

    const height = visibleHeight * ratio;
    setScrollbarHeight(height);
  }, []);

  const onScroll = useCallback(() => {
    const { scrollHeight } = document.documentElement;
    const offset = scrollHeight - (containerRef.current?.scrollHeight ?? 0);

    const ratio = offset / scrollHeight;
    const weight = window.scrollY * ratio;

    setScrollbarPosition(window.scrollY + weight);
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(onResize);
    observer.observe(document.documentElement);
    window.addEventListener("scroll", onScroll);

    return () => {
      observer.unobserve(document.documentElement);
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return scrollbarIsNeeded ? (
    <div className="grid grid-cols-[1fr_3rem]" ref={containerRef}>
      {children}
      <div className="relative bg-neutral-900">
        <div
          className="absolute bg-slate-800 w-full"
          style={{
            height: convertToRem(scrollbarHeight),
            top: convertToRem(scrollbarPosition),
          }}
        />
      </div>
    </div>
  ) : (
    children
  );
};
