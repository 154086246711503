import {
  AudioContext,
  type IAudioContext,
  type TAnyAudioBuffer,
} from "standardized-audio-context";
import { pitches } from "~/constants";
import type { AudioAsset, Key, SPN, StoredSound } from "~/types";
import { isNonNullable } from "~/utils/misc";

const isFulfilled = <T>(
  p: PromiseSettledResult<T>
): p is PromiseFulfilledResult<T> => p.status === "fulfilled";

const convertToAudioBuffers = async (
  audioContext: IAudioContext,
  files: StoredSound[]
) => {
  const results = await Promise.allSettled(
    files.map(async ({ binaries, ...rest }) => {
      try {
        const audioBuffer = await audioContext.decodeAudioData(binaries);
        return { audioBuffer: audioBuffer as TAnyAudioBuffer, ...rest };
      } catch (error) {
        if (error instanceof DOMException) {
          console.debug(error);
          // todo: log to analytics if EncodingError
        }
      }
    })
  );

  return results
    .filter(isFulfilled)
    .map(({ value }) => value)
    .filter(isNonNullable);
};

function pairToPitch<T>(audioAssets: T[]) {
  const length = Math.min(audioAssets.length, pitches.length);

  return Array.from({ length }, (_, index): [SPN, T] => [
    pitches[index],
    audioAssets[index],
  ]);
}

const mapAudioAssets = (audioAssets: AudioAsset[]) => {
  const sampleMap = pairToPitch(audioAssets);
  const paths = [...new Set(sampleMap.map(([, { path }]) => path ?? ""))];
  const keyMap = sampleMap.map(([pitch, { name, path }]) => ({
    colorCode: paths.indexOf(path ?? ""),
    name,
    pitch,
  }));
  const audioMap = new Map(sampleMap);
  return { keyMap, audioMap };
};

export interface AudioPlayer {
  keys: Key[];
  trigger: (key: SPN) => void;
}

export default async function createAudioPlayer(
  arrayBuffers: StoredSound[]
): Promise<AudioPlayer> {
  const audioContext = new AudioContext();

  const audioBuffers = await convertToAudioBuffers(audioContext, arrayBuffers);

  const { keyMap, audioMap } = mapAudioAssets(audioBuffers);

  const trigger = (key: SPN) => {
    const source = audioContext.createBufferSource();
    const audioBuffer = audioMap.get(key)?.audioBuffer;
    if (audioBuffer) {
      source.buffer = audioBuffer;
    }
    source.connect(audioContext.destination);
    source.start();
  };

  return { keys: keyMap, trigger };
}

export const emptyAudioPlayer = {
  keys: [],
  trigger: () => void {},
};
