import { type ReactNode, createContext, useContext, useReducer } from "react";

export type LoadingState = {
  buildId?: string;
  deferredId?: string;
  fetchedId?: string;
  isBuilding: boolean;
  isLoading: boolean;
  message: string;
};

type ContextType = {
  state: LoadingState;
  dispatch: (action: Action) => void;
};

// Actions
export enum ActionType {
  SetFetchedId = "SET_FETCHED_ID",
  SetBuildId = "SET_BUILD_ID",
  SetIsBuilding = "SET_IS_BUILDING",
}

type ActionPayloads = {
  [ActionType.SetFetchedId]: string;
  [ActionType.SetBuildId]: string;
  [ActionType.SetIsBuilding]: boolean;
};

type ActionMap<M extends Record<string, string | boolean>> = {
  [Key in keyof M]: { type: Key; payload: M[Key] };
};

type Action = ActionMap<ActionPayloads>[keyof ActionMap<ActionPayloads>];

const initialState: LoadingState = {
  isLoading: false,
  isBuilding: false,
  message: "Scouting for the kit on Google Drive",
};

const loadingStateReducer = (state: LoadingState, action: Action) => {
  switch (action.type) {
    case ActionType.SetFetchedId:
      return {
        ...state,
        fetchedId: action.payload,
        message: "Downloading kit",
      };
    case ActionType.SetBuildId:
      return {
        ...state,
        buildId: action.payload,
        message: "Creating launchpad",
      };
    case ActionType.SetIsBuilding:
      return {
        ...state,
        isBuilding: action.payload,
      };
    default:
      return state;
  }
};

const LoaderContext = createContext<ContextType | undefined>(undefined);

export const useLoader = () => {
  const context = useContext(LoaderContext);

  if (context === undefined) {
    throw new Error("`useLoader` must be used within a LoaderProvider");
  }

  return context;
};

interface Props {
  children: ReactNode;
  deferredId: string;
}

export const LoaderProvider = ({ children, deferredId }: Props) => {
  const [state, dispatch] = useReducer(loadingStateReducer, initialState);

  const isLoading =
    deferredId !== state.fetchedId ||
    deferredId !== state.buildId ||
    state.isBuilding;

  return (
    <LoaderContext.Provider
      value={{ state: { ...state, isLoading }, dispatch }}
    >
      {children}
    </LoaderContext.Provider>
  );
};
