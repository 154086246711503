import { useLiveQuery } from "dexie-react-hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PadGrid } from "./Pads";
import { idb } from "~/libs/idb";
import createAudioPlayer, {
  emptyAudioPlayer,
  type AudioPlayer,
} from "~/libs/audio-player";
import { pitches } from "~/constants";
import { MobileScrollBar } from "../MobileScrollBar";
import styles from "./styles.module.css";
import { IconDownload } from "../icons";
import { Loader } from "../Loader";
import { useQuery } from "@tanstack/react-query";
import { ActionType, useLoader } from "~/providers/loader";
import { KitMeta } from "~/types";

interface Props {
  kitId?: string;
}

export const LaunchpadBuilder = ({ kitId }: Props) => {
  const metadata = useLiveQuery(() => idb.metadata.limit(1).first());
  const files = useLiveQuery(() => idb.files.toArray());

  const { dispatch, state } = useLoader();

  const { isLoading, data: player } = useQuery<AudioPlayer>({
    queryKey: ["audioPlayer", files],
    queryFn: async ({ queryKey }) => {
      dispatch({ type: ActionType.SetBuildId, payload: metadata?.publicId! });
      // Ensure that the second item in queryKey is an array of StoredSound
      const [, filesArray] = queryKey;
      if (!Array.isArray(filesArray)) {
        throw new Error("files must be an array of StoredSound");
      }
      return await createAudioPlayer(filesArray);
    },
    enabled: !!files && metadata?.publicId === kitId, // The query will not execute until the files are truthy
    initialData: emptyAudioPlayer,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isLoading) {
      dispatch({ type: ActionType.SetIsBuilding, payload: isLoading });
    }
  }, [isLoading]);

  return (
    <Launchpads
      isLoading={state.isLoading}
      metadata={metadata}
      player={player}
    />
  );
};

export const ParentLoader = () => {
  const { state } = useLoader();

  return state.isLoading ? <Loader message={state.message} /> : null;
};

interface LaunchpadsProps {
  player: AudioPlayer;
  isLoading?: boolean;
  metadata?: KitMeta;
}

export const Launchpads = ({
  isLoading,
  metadata,
  player,
}: LaunchpadsProps) => {
  const [distanceFromTop, setDistanceFromTop] = useState(0);

  const divTop = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setDistanceFromTop(node.getBoundingClientRect().top);
    }
  }, []);

  const { length: numberOfSamples } = player.keys;

  const [screenWidth, setScreenWidth] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);

  const { keys, numberOfColumns } = useMemo(() => {
    const remainingHeight = screenHeight - distanceFromTop;

    const maxNumberOfColumns = Math.floor(screenWidth / 150);

    const numberOfRowThatFitsScreenHeight = Math.floor(remainingHeight / 150);

    const numberOfInitialColumns = Math.min(maxNumberOfColumns, 4);

    const numberOfInitialRows = Math.min(numberOfRowThatFitsScreenHeight, 4);

    const numberOfMinKeys = numberOfInitialRows * numberOfInitialColumns;

    const numberOfColumns =
      numberOfSamples > numberOfMinKeys
        ? numberOfSamples / numberOfInitialRows < maxNumberOfColumns
          ? Math.floor(numberOfSamples / numberOfInitialRows)
          : maxNumberOfColumns
        : numberOfInitialColumns;

    const numberOfRows = Math.ceil(numberOfSamples / numberOfColumns);

    const numberOfKeys =
      numberOfSamples > numberOfMinKeys
        ? numberOfColumns * numberOfRows
        : numberOfMinKeys;

    const deadPitches = pitches.slice(numberOfSamples, numberOfKeys);
    const deadKeys = deadPitches.map((pitch) => ({ pitch }));

    return { numberOfColumns, keys: [player.keys, deadKeys].flat() };
  }, [
    distanceFromTop,
    numberOfSamples,
    screenWidth,
    screenHeight,
    player.keys,
  ]);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  }, []);

  return isLoading ? null : (
    <div
      className={`w-full mx-2.5 ${
        styles[`launchpadsMaxWidth${numberOfColumns}`]
      }`}
      ref={divTop}
    >
      <Head metadata={metadata} />
      <MobileScrollBar>
        <PadGrid
          columns={numberOfColumns}
          keys={keys}
          trigger={player.trigger}
        />
      </MobileScrollBar>
    </div>
  );
};

interface HeadProps {
  metadata?: KitMeta;
}

const Head = ({ metadata }: HeadProps) => (
  <div className="flex h-14 items-center">
    {metadata ? (
      <div className="flex items-start">
        {metadata.downloadUrl && (
          <button
            aria-label="Download sound kit"
            onClick={() => location.replace(metadata.downloadUrl!)}
            className="pe-2"
          >
            <IconDownload
              className="fill-[oklch(var(--p))]"
              width="25"
              height="25"
            />
          </button>
        )}
        <div>
          {metadata.name && (
            <h1 className="leading-none text-sm">{metadata.name}</h1>
          )}
          {metadata.provider && (
            <span className="text-[.7rem] text-slate-500">
              Source: {metadata.provider}
            </span>
          )}
        </div>
      </div>
    ) : null}
  </div>
);
