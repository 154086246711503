/**
 * Provide a condition and if that condition is falsey, this throws an error
 * with the given message.
 *
 * inspired by invariant from 'tiny-invariant' except will still include the
 * message in production.
 *
 * @example
 * invariant(typeof value === 'string', `value must be a string`)
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Error} if condition is falsey
 */
export function invariant(
  condition: any,
  message: string | (() => string)
): asserts condition {
  if (!condition) {
    throw new Error(typeof message === "function" ? message() : message);
  }
}

// Dev utils
/**
 * @example
 * await wait(2000);
 */
export const wait = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

export function debug() {
  return;
}

/** @description remove '.zip' if it appears at the end of the string */
export const removeZipExtension = (input: string): string =>
  input.replace(/\.zip$/, "");

export const isNonNullable = <T>(item: T | null | undefined): item is T =>
  !!item;
