import { useState, useEffect, useRef, MouseEvent, TouchEvent } from "react";
import type { Key, SPN } from "~/types";
import styles from "./styles.module.css";
import { createListeners } from "~/libs/listeners";

enum Colors {
  Purple = "purple",
  Blue = "blue",
  Yellow = "yellow",
  Red = "red",
  Green = "green",
  Brown = "brown",
}

const colorCodes = Object.values(Colors);

const pickColor = (code: number) => colorCodes[code % colorCodes.length];

// DEV ONLY: turn on to have pads randomly lit up
const randomness = false;

interface PadProps {
  colorCode?: number;
  id: SPN;
  name?: string;
}

const Pad = ({ id, name, colorCode }: PadProps) => {
  const color =
    colorCode != null ? `pad--${pickColor(colorCode)}` : "pad--dead";

  const randomBoolean = randomness ? Math.random() > 0.5 : false;

  const [active, setActive] = useState(false);

  const handlePress = () => setActive(true);

  const handleUnpress = () => setActive(false);

  return (
    <button
      className={`flex items-end p-2.5 text-left cursor-grab touch-manipulation group text-slate-400 first:row-[1/1] first:col-[1/1] user-select ${
        styles.pad
      } ${styles[color]} ${randomBoolean || active ? styles.active : ""}`}
      data-pad="true"
      id={id}
      onMouseDown={handlePress}
      onMouseUp={handleUnpress}
      onTouchEnd={handleUnpress}
      onTouchStart={handlePress}
    >
      <span className="cursor-default pointer-events-none group-hover:visible overflow-hidden text-xs lg:invisible">
        {name}
      </span>
    </button>
  );
};

interface PadGridProps {
  columns: number;
  keys: Key[];
  trigger: (spn: SPN) => void;
}

export const PadGrid = ({ columns, keys, trigger }: PadGridProps) => {
  const controllerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let controllerRefValue: HTMLDivElement | null = null;
    const { addListeners, removeListeners } = createListeners(trigger);

    if (controllerRef.current) {
      addListeners(controllerRef.current);
      controllerRefValue = controllerRef.current;
    }

    return () => {
      if (controllerRefValue) removeListeners(controllerRefValue);
    };
  }, [trigger]);

  return (
    <div
      className={`grid gap-1 auto-rows-[1fr] before:content-[''] before:pb-[100%] before:row-[1/1] before:col-[1/1] ${
        styles.padGrid
      } ${styles[`padGrid-${columns}`]}`}
      ref={controllerRef}
    >
      {keys.map(({ pitch, name, colorCode }) => (
        <Pad colorCode={colorCode} id={pitch} key={pitch} name={name} />
      ))}
    </div>
  );
};
